body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* DESIGN VARIABLES  */
:root {
  --color-primary: rgb(77, 77, 234);
  --color-bg: rgb(168, 245, 182);
  --color-light: #f4f4f4;
  --color-navbg: #000000;
  --color-logo: #de0909;
  --color-beta: #5EEAD4;
  --color-text: #E4E4E7;
  --color-footerbg: #000001;
  --color-footerborder: #52525B;
  --color-bg-dashboard: #FAF9F6;
  --color-dark: #000000;

}

main {
  text-align: center;
  padding-top: 90px;
}


.blurry::before {
  content: 'xxxxx';
  padding: 0.25rem;
  background: linear-gradient(90deg, #44BCFF -20.67%, #FF44EC 29.33%, #FF675E 79.33%);
  opacity: 0.3;
  filter: blur(8px);
  position: absolute;
  transform: translateX(0%);

}

/* NAVIGATION MENU  */

.header {
  min-height: 90px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;
  backdrop-filter: blur(18px);
}

.logo {
  color: var(--color-logo) !important;
  font-size: 1.3rem;
  font-weight: 700;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-btn-dark:hover {
  color: black !important
}

.nav-btn-light:hover {
  color: white !important
}

.fa-bars {
  color: white;
  font-size: 30px;
}

.nav-item {
  margin-right: 1rem;
}

.main-nav {
  width: 80%;
  margin: 0 auto;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .main-nav {
    width: 95%;
    margin: 0 auto;
    align-items: start;
  }

  .sec-nav {
    margin-top: 1rem
  }
}


/*this is for styling our draggable component*/
.react-resizable {
  position: absolute !important;
  top: 0px;
  left: 0px;
}

.light-theme {
  color: var(--color-dark) !important
}

.dark-theme {
  color: var(--color-light)
}

.full-height {
  padding-bottom: 150px;
  padding-top: 70px;
}

/* cutout area styling */

.cutout-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.7);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-sign {
  font-size: 5rem;
  color: #333;
}

/* agreed checkbox on the forms */
.checkbox-agreed {
  transform: scale(1.5);
  margin-right: 8px;
}

@media screen and (max-width: 920px) {
  .full-height {
    padding-bottom: 100px;
    padding-top: 40px;
  }
}


/* Home page HERO or banner SECTION  */

.home-hero-section {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  background: linear-gradient(90deg, #010728 -20.67%, #1c081a 29.33%, #0b0101 79.33%);
  color: var(--color-text);
}

.h1-main {
  font-size: 96px !important;
  font-weight: 700;
  width: 60%;
  line-height: 0.9;
  margin-bottom: 0;
}

.h3-main {
  font-size: 48px;
  margin: 2.5rem;
}

.buttonBanner {
  border: 1px solid var(--color-light);
  margin-top: 1.5rem;
  padding: 1rem 5rem;
  text-transform: uppercase;
  background-color: var(--color-light);
  color: #000000;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 2px 2px 8px 8px #21183fd3;

}

.buttonBanner:hover {
  background-color: transparent;
  color: var(--color-light);
  transition: all 0.4s ease;
}

@media screen and (max-width: 920px) {
  .h1-main {
    font-size: 48px !important;
    font-weight: 700;
    width: 80%;
    line-height: 1;
    margin-bottom: 0;
  }

  .h3-main {
    font-size: 24px;
    margin: 2.5rem;
  }
}

/* How it works section styling */
.how-it-works>h3 {
  font-size: 48px;
  font-weight: 700;

}

.features-works {
  padding-bottom: 8rem;
}

.how-it-works {
  margin: 3rem auto;
}

h4 {
  font-size: 38px;
  margin-bottom: 0;
}

h5 {
  font-size: 28px;
  margin-bottom: 0;
}

.upload-poster {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 80%;
  margin: 5rem auto;
  box-sizing: border-box;

}

.upload-img>img {
  width: 90%;
  height: 90%;
}

@media screen and (max-width: 760px) {
  .upload-poster {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    margin: 5rem auto;
    box-sizing: border-box;
  }

  .how-it-works>h3 {
    font-size: 36px;
    font-weight: 700;

  }
}

/* home page start now section */

/* CALL TO ACTION  */

.call-to-action {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(1, 2, 21);
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%);
  margin:0;
}

.cta {
  margin: 2rem;
  color: white;
}

h3.cta {
  font-size: 48px;
  color: white;
}

@media screen and (max-width: 600px) {
  .call-to-action {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1, 2, 21);
    clip-path: none;
    margin: 0;
  }

  h3.cta {
    font-size: 36px
  }
}

/* contact page styling */
.contact-section {
  width: 70%;
  padding-bottom: 8rem;

}

/* pricing page styling */
.pricing {
  padding-bottom: 8rem;
}

.price-tag {
  border-top: 1px solid var(--color-footerborder);
  padding-top: 4rem;
}

.price-text {
  margin-top: 0;
}

.price-text>span {
  text-decoration: none;
}


/* all forms and accounts-related pages styling */

.account-section {
  padding-bottom: 8rem;
}

.input {
  width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  font-size: 1.2rem;
  border: 1px solid var(--color-footerborder);
  padding: 1rem;
  margin: 1rem 0;
}

h2.signup {
  font-size: 48px;
}

h2.event {
  font-size: 48px;
}

.lbl-title {
  margin-top: 0.8rem;
  margin-bottom: 0;
  transform: translateY(1px);
  width: 100%;
  text-align: left;
}

p {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 200;
}

p>span {
  cursor: pointer;
  text-decoration: underline;
}

.required {
  margin-top: 0;
  transform: translateY(-12px);
  color: red;
  text-align: left;
}


.form-button {
  margin-top: 1rem;
  width: 80%;
  padding: 1rem;
  text-transform: uppercase;
  background-color: var(--color-light);
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
}

.form-button:hover {
  margin-top: 1rem;
  width: 80%;
  padding: 1rem;
  text-transform: uppercase;
  background-color: var(--color-dark);
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
}

.alert-success {
  font-weight: 300;
  padding: 1.2rem 1.5rem;
  color: white;
  background-color: rgb(30, 180, 30);
  border-radius: 6px;
  margin-bottom: 3rem;
}

.alert-warning {
  font-weight: 300;
  padding: 1.2rem 1.5rem;
  color: black;
  border-radius: 6px;
  margin-bottom: 3rem;
}

.alert-danger {
  font-weight: 300;
  padding: 1.2rem 1.5rem;
  color: white;
  background-color: rgb(223, 21, 21);
  border-radius: 6px;
  margin-bottom: 3rem;

}

.showpassword {
  display: inline-block;
  font-size: 1rem;
  font-weight: 200;
}

.form-text {
  text-align: left;
  font-size: 1rem;
}


/* cutout, cart and payment section styling */
.cutout-title {
  font-size: 1.6rem;
}

.payment-icon {
  width: 100%;
  margin: 5rem auto;
}

.payment-status {
  font-weight: 600;
  color: white;
  border-radius: 6px;
  margin: 2rem auto 0;
}

.cart-input {
  text-align: center;
  background-color: transparent;
}

.payment-form {
  width: 100%
}

/* Dashboard landing styling */
.h2-main {
  font-size: 48px;
}

.button-events {
  width: 5 0%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
}

.dashboard-p {
  padding: 0;
  margin: 0;
}

.aligns-left {
  text-align: left;
}

/* Accounts page navlinks styling */


.profile-title {
  text-align: left;
  margin-bottom: 3rem;
}


.profile-links {
  background-color: #f7f7f7;
  margin: 0.3rem auto;

}

.profile-links-light {
  background-color: #f7f7f7;
  margin: 0.3rem auto;
  border: 1px solid black
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active {
  background-color: black;
  color: white;
  border: 1px solid white;
}

/* Footer styling */
/* FOOTER */

footer {
  height: 393px;
  width: 100%;
  text-align: center;
  background-color: var(--color-footerbg);
  color: var(--color-text);
  position: relative;

}

.footer-top {
  height: 257px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid var(--color-footerborder);
}

.footer-top>div {
  width: 100%;
  display: flex;
  align-content: flex-start;
}


.footer-bottom {

  width: 80%;
  height: 136px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.foot-logo {
  color: var(--color-logo);
  font-size: 1.3rem;
  margin-top: 0;
}

.footer-links {
  font-size: 0.8rem;
  width: 40%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.navlinks {
  text-decoration: none;
}

.navlinks :hover {
  text-decoration: none;
  color: var(--color-text);
}

small {
  font-size: 0.8rem;
  font-weight: 200;
}

.foot-links {
  color: var(--color-light);
  text-decoration: none;
  font-size: 1rem;
}

@media screen and (max-width: 575px) {
  footer {
    height: max-content;
  }

  .footer-top {
    height: max-content;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-footerborder);
  }

  .footer-top>div {
    flex-direction: column;
    align-items: center;
  }

  .foot-logo {
    color: var(--color-logo);
    font-size: 1.3rem;
    margin: 20px auto;
  }

  .footer-bottom {
    height: max-content;
    margin: 20px auto 0;
    flex-direction: column;
    padding-bottom: 30px;
  }

}